.mmpopup-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 90000;
  background: $modalBackground;
}

.mmpopup-popup {
  z-index: 99000;
  position: fixed;
  height: auto;
  max-height: 80vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px 20px 0 20px;
  overflow: auto;
  border: 1px solid $boxBorderColor;
  background: $bodyBackground;
}

.mmpopup-popup.nopadding {
  padding: 0;
}

.mmpopup-small {
  width: 35%;
  min-height: 2rem;
  padding: 15px 15px 0 15px;
}

.mmpopup-popup h2 {
  font-size: 1.4rem;
}

.mmpopup-small h3 {
  font-size: 1.1rem;
}

.mmpopup-medium {
  width: 50%;
  min-height: 2rem;
}

.mmpopup-large {
  width: 1200px;
  height: 96vh;
}

.mmpopup-fullscreen {
  width: 98%;
  height: calc(100vh - 60px - 4%);
  top: calc(60px + 2%);
  left: 1%;
  transform: none;
  padding: 60px 20px;
}

@media screen and (max-width: $breakSmall) {
  .mmpopup-small {
    width: 100%;
    margin: 0;
    height: 100vh;
    padding-top: 50px;
  }

  .mmpopup-medium {
    width: 100%;
    margin: 0;
    height: 100vh;
    padding-top: 50px;
  }

  .mmpopup-large {
    width: 100%;
    margin: 0;
    height: 100vh;
    padding-top: 50px;
  }

  .mmpopup-fullscreen {
    width: 100%;
    margin: 0;
    height: 100vh;
    padding-top: 50px;
  }
}

.mmpopup-popup h3 {
  line-height: 40px;
}

.mmpopup-close-button {
  display: inline-block;
  position: absolute;
  top: 16px;
  right: 18px;
  cursor: pointer;
  z-index: 20000;
}

.mmpopup-close-button img {
  width: 26px;
  height: 26px;
}

.mmpopup-buttons {
  text-align: right;
}

.popup-buttons {
  text-align: right;
  padding-top: $primaryPadding / 2;
  padding-bottom: $primaryPadding;

  .button {
    margin-bottom: 0;
  }
}
