.helpdesk-helpdesk {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    a {
      display: inline-block;
      padding: 4px 6px;
      line-height: 140%;
    }

    a:hover {
      background-color: $hoverColor;
    }
  }
}

.helpdesk-area {
  margin-bottom: 1.2rem;
}

.helpdesk-question-popup {
  .mmpopup-popup {
    padding: $primaryPadding * 2;
  }

  .question-popup-logo {
    margin-bottom: 1.5rem;

    img {
      height: 30px;
    }
  }

  h2 {
    color: $primaryColor;
    font-size: 1.1rem !important;
    font-weight: 600;
    padding-right: 40px;
    margin-bottom: 1rem;
  }

  .question-popup-answer {
    font-size: 0.9rem;
  }
}
