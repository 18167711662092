/* source-sans-pro-200 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/source-sans-pro-v19-latin-200.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/source-sans-pro-v19-latin-200.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/source-sans-pro-v19-latin-200.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/source-sans-pro-v19-latin-200.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/source-sans-pro-v19-latin-200.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/source-sans-pro-v19-latin-200.svg#SourceSansPro")
      format("svg"); /* Legacy iOS */
}
/* source-sans-pro-200italic - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 200;
  src: url("../fonts/source-sans-pro-v19-latin-200italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/source-sans-pro-v19-latin-200italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/source-sans-pro-v19-latin-200italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/source-sans-pro-v19-latin-200italic.woff") format("woff"),
    /* Modern Browsers */
      url("../fonts/source-sans-pro-v19-latin-200italic.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/source-sans-pro-v19-latin-200italic.svg#SourceSansPro")
      format("svg"); /* Legacy iOS */
}
/* source-sans-pro-300 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/source-sans-pro-v19-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/source-sans-pro-v19-latin-300.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/source-sans-pro-v19-latin-300.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/source-sans-pro-v19-latin-300.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/source-sans-pro-v19-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/source-sans-pro-v19-latin-300.svg#SourceSansPro")
      format("svg"); /* Legacy iOS */
}
/* source-sans-pro-300italic - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/source-sans-pro-v19-latin-300italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/source-sans-pro-v19-latin-300italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/source-sans-pro-v19-latin-300italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/source-sans-pro-v19-latin-300italic.woff") format("woff"),
    /* Modern Browsers */
      url("../fonts/source-sans-pro-v19-latin-300italic.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/source-sans-pro-v19-latin-300italic.svg#SourceSansPro")
      format("svg"); /* Legacy iOS */
}
/* source-sans-pro-regular - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/source-sans-pro-v19-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/source-sans-pro-v19-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/source-sans-pro-v19-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/source-sans-pro-v19-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/source-sans-pro-v19-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/source-sans-pro-v19-latin-regular.svg#SourceSansPro")
      format("svg"); /* Legacy iOS */
}
/* source-sans-pro-italic - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/source-sans-pro-v19-latin-italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/source-sans-pro-v19-latin-italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/source-sans-pro-v19-latin-italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/source-sans-pro-v19-latin-italic.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/source-sans-pro-v19-latin-italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/source-sans-pro-v19-latin-italic.svg#SourceSansPro")
      format("svg"); /* Legacy iOS */
}
/* source-sans-pro-600 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/source-sans-pro-v19-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/source-sans-pro-v19-latin-600.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/source-sans-pro-v19-latin-600.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/source-sans-pro-v19-latin-600.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/source-sans-pro-v19-latin-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/source-sans-pro-v19-latin-600.svg#SourceSansPro")
      format("svg"); /* Legacy iOS */
}
/* source-sans-pro-600italic - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 600;
  src: url("../fonts/source-sans-pro-v19-latin-600italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/source-sans-pro-v19-latin-600italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/source-sans-pro-v19-latin-600italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/source-sans-pro-v19-latin-600italic.woff") format("woff"),
    /* Modern Browsers */
      url("../fonts/source-sans-pro-v19-latin-600italic.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/source-sans-pro-v19-latin-600italic.svg#SourceSansPro")
      format("svg"); /* Legacy iOS */
}
/* source-sans-pro-700 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/source-sans-pro-v19-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/source-sans-pro-v19-latin-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/source-sans-pro-v19-latin-700.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/source-sans-pro-v19-latin-700.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/source-sans-pro-v19-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/source-sans-pro-v19-latin-700.svg#SourceSansPro")
      format("svg"); /* Legacy iOS */
}
/* source-sans-pro-700italic - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/source-sans-pro-v19-latin-700italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/source-sans-pro-v19-latin-700italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/source-sans-pro-v19-latin-700italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/source-sans-pro-v19-latin-700italic.woff") format("woff"),
    /* Modern Browsers */
      url("../fonts/source-sans-pro-v19-latin-700italic.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/source-sans-pro-v19-latin-700italic.svg#SourceSansPro")
      format("svg"); /* Legacy iOS */
}
/* source-sans-pro-900 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/source-sans-pro-v19-latin-900.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/source-sans-pro-v19-latin-900.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/source-sans-pro-v19-latin-900.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/source-sans-pro-v19-latin-900.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/source-sans-pro-v19-latin-900.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/source-sans-pro-v19-latin-900.svg#SourceSansPro")
      format("svg"); /* Legacy iOS */
}
/* source-sans-pro-900italic - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 900;
  src: url("../fonts/source-sans-pro-v19-latin-900italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/source-sans-pro-v19-latin-900italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/source-sans-pro-v19-latin-900italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/source-sans-pro-v19-latin-900italic.woff") format("woff"),
    /* Modern Browsers */
      url("../fonts/source-sans-pro-v19-latin-900italic.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/source-sans-pro-v19-latin-900italic.svg#SourceSansPro")
      format("svg"); /* Legacy iOS */
}

/* work-sans-100 - latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 100;
  src: url("../fonts/work-sans-v16-latin-100.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/work-sans-v16-latin-100.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/work-sans-v16-latin-100.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/work-sans-v16-latin-100.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/work-sans-v16-latin-100.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/work-sans-v16-latin-100.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-200 - latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/work-sans-v16-latin-200.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/work-sans-v16-latin-200.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/work-sans-v16-latin-200.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/work-sans-v16-latin-200.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/work-sans-v16-latin-200.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/work-sans-v16-latin-200.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-300 - latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/work-sans-v16-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/work-sans-v16-latin-300.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/work-sans-v16-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/work-sans-v16-latin-300.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/work-sans-v16-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/work-sans-v16-latin-300.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-regular - latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/work-sans-v16-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/work-sans-v16-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/work-sans-v16-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../fonts/work-sans-v16-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/work-sans-v16-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/work-sans-v16-latin-regular.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-500 - latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/work-sans-v16-latin-500.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/work-sans-v16-latin-500.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/work-sans-v16-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/work-sans-v16-latin-500.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/work-sans-v16-latin-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/work-sans-v16-latin-500.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-600 - latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/work-sans-v16-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/work-sans-v16-latin-600.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/work-sans-v16-latin-600.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/work-sans-v16-latin-600.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/work-sans-v16-latin-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/work-sans-v16-latin-600.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-700 - latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/work-sans-v16-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/work-sans-v16-latin-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/work-sans-v16-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/work-sans-v16-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/work-sans-v16-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/work-sans-v16-latin-700.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-800 - latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/work-sans-v16-latin-800.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/work-sans-v16-latin-800.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/work-sans-v16-latin-800.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/work-sans-v16-latin-800.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/work-sans-v16-latin-800.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/work-sans-v16-latin-800.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-900 - latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/work-sans-v16-latin-900.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/work-sans-v16-latin-900.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/work-sans-v16-latin-900.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/work-sans-v16-latin-900.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/work-sans-v16-latin-900.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/work-sans-v16-latin-900.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-100italic - latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 100;
  src: url("../fonts/work-sans-v16-latin-100italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/work-sans-v16-latin-100italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/work-sans-v16-latin-100italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/work-sans-v16-latin-100italic.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/work-sans-v16-latin-100italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/work-sans-v16-latin-100italic.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-200italic - latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 200;
  src: url("../fonts/work-sans-v16-latin-200italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/work-sans-v16-latin-200italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/work-sans-v16-latin-200italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/work-sans-v16-latin-200italic.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/work-sans-v16-latin-200italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/work-sans-v16-latin-200italic.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-300italic - latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/work-sans-v16-latin-300italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/work-sans-v16-latin-300italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/work-sans-v16-latin-300italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/work-sans-v16-latin-300italic.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/work-sans-v16-latin-300italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/work-sans-v16-latin-300italic.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-italic - latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/work-sans-v16-latin-italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/work-sans-v16-latin-italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/work-sans-v16-latin-italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../fonts/work-sans-v16-latin-italic.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/work-sans-v16-latin-italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/work-sans-v16-latin-italic.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-500italic - latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/work-sans-v16-latin-500italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/work-sans-v16-latin-500italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/work-sans-v16-latin-500italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/work-sans-v16-latin-500italic.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/work-sans-v16-latin-500italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/work-sans-v16-latin-500italic.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-600italic - latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 600;
  src: url("../fonts/work-sans-v16-latin-600italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/work-sans-v16-latin-600italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/work-sans-v16-latin-600italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/work-sans-v16-latin-600italic.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/work-sans-v16-latin-600italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/work-sans-v16-latin-600italic.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-700italic - latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/work-sans-v16-latin-700italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/work-sans-v16-latin-700italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/work-sans-v16-latin-700italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/work-sans-v16-latin-700italic.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/work-sans-v16-latin-700italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/work-sans-v16-latin-700italic.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-800italic - latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 800;
  src: url("../fonts/work-sans-v16-latin-800italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/work-sans-v16-latin-800italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/work-sans-v16-latin-800italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/work-sans-v16-latin-800italic.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/work-sans-v16-latin-800italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/work-sans-v16-latin-800italic.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-900italic - latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 900;
  src: url("../fonts/work-sans-v16-latin-900italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/work-sans-v16-latin-900italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/work-sans-v16-latin-900italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/work-sans-v16-latin-900italic.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/work-sans-v16-latin-900italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/work-sans-v16-latin-900italic.svg#WorkSans") format("svg"); /* Legacy iOS */
}

/* poppins-100 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  src: url("../fonts/poppins-v19-latin-100.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/poppins-v19-latin-100.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/poppins-v19-latin-100.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/poppins-v19-latin-100.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/poppins-v19-latin-100.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/poppins-v19-latin-100.svg#Poppins")
      format("svg"); /* Legacy iOS */
}
/* poppins-100italic - latin */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 100;
  src: url("../fonts/poppins-v19-latin-100italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/poppins-v19-latin-100italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/poppins-v19-latin-100italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../fonts/poppins-v19-latin-100italic.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/poppins-v19-latin-100italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/poppins-v19-latin-100italic.svg#Poppins") format("svg"); /* Legacy iOS */
}
/* poppins-200 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/poppins-v19-latin-200.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/poppins-v19-latin-200.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/poppins-v19-latin-200.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/poppins-v19-latin-200.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/poppins-v19-latin-200.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/poppins-v19-latin-200.svg#Poppins")
      format("svg"); /* Legacy iOS */
}
/* poppins-200italic - latin */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 200;
  src: url("../fonts/poppins-v19-latin-200italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/poppins-v19-latin-200italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/poppins-v19-latin-200italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../fonts/poppins-v19-latin-200italic.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/poppins-v19-latin-200italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/poppins-v19-latin-200italic.svg#Poppins") format("svg"); /* Legacy iOS */
}
/* poppins-300 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/poppins-v19-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/poppins-v19-latin-300.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/poppins-v19-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/poppins-v19-latin-300.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/poppins-v19-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/poppins-v19-latin-300.svg#Poppins")
      format("svg"); /* Legacy iOS */
}
/* poppins-300italic - latin */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/poppins-v19-latin-300italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/poppins-v19-latin-300italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/poppins-v19-latin-300italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../fonts/poppins-v19-latin-300italic.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/poppins-v19-latin-300italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/poppins-v19-latin-300italic.svg#Poppins") format("svg"); /* Legacy iOS */
}
/* poppins-regular - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/poppins-v19-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/poppins-v19-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/poppins-v19-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../fonts/poppins-v19-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/poppins-v19-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/poppins-v19-latin-regular.svg#Poppins") format("svg"); /* Legacy iOS */
}
/* poppins-italic - latin */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/poppins-v19-latin-italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/poppins-v19-latin-italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/poppins-v19-latin-italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/poppins-v19-latin-italic.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/poppins-v19-latin-italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/poppins-v19-latin-italic.svg#Poppins") format("svg"); /* Legacy iOS */
}
/* poppins-500 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/poppins-v19-latin-500.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/poppins-v19-latin-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/poppins-v19-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/poppins-v19-latin-500.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/poppins-v19-latin-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/poppins-v19-latin-500.svg#Poppins")
      format("svg"); /* Legacy iOS */
}
/* poppins-500italic - latin */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/poppins-v19-latin-500italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/poppins-v19-latin-500italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/poppins-v19-latin-500italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../fonts/poppins-v19-latin-500italic.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/poppins-v19-latin-500italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/poppins-v19-latin-500italic.svg#Poppins") format("svg"); /* Legacy iOS */
}
/* poppins-600 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/poppins-v19-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/poppins-v19-latin-600.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/poppins-v19-latin-600.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/poppins-v19-latin-600.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/poppins-v19-latin-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/poppins-v19-latin-600.svg#Poppins")
      format("svg"); /* Legacy iOS */
}
/* poppins-600italic - latin */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 600;
  src: url("../fonts/poppins-v19-latin-600italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/poppins-v19-latin-600italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/poppins-v19-latin-600italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../fonts/poppins-v19-latin-600italic.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/poppins-v19-latin-600italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/poppins-v19-latin-600italic.svg#Poppins") format("svg"); /* Legacy iOS */
}
/* poppins-700 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/poppins-v19-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/poppins-v19-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/poppins-v19-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/poppins-v19-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/poppins-v19-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/poppins-v19-latin-700.svg#Poppins")
      format("svg"); /* Legacy iOS */
}
/* poppins-700italic - latin */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/poppins-v19-latin-700italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/poppins-v19-latin-700italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/poppins-v19-latin-700italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../fonts/poppins-v19-latin-700italic.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/poppins-v19-latin-700italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/poppins-v19-latin-700italic.svg#Poppins") format("svg"); /* Legacy iOS */
}
/* poppins-800 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/poppins-v19-latin-800.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/poppins-v19-latin-800.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/poppins-v19-latin-800.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/poppins-v19-latin-800.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/poppins-v19-latin-800.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/poppins-v19-latin-800.svg#Poppins")
      format("svg"); /* Legacy iOS */
}
/* poppins-800italic - latin */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 800;
  src: url("../fonts/poppins-v19-latin-800italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/poppins-v19-latin-800italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/poppins-v19-latin-800italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../fonts/poppins-v19-latin-800italic.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/poppins-v19-latin-800italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/poppins-v19-latin-800italic.svg#Poppins") format("svg"); /* Legacy iOS */
}
/* poppins-900 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/poppins-v19-latin-900.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/poppins-v19-latin-900.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/poppins-v19-latin-900.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/poppins-v19-latin-900.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/poppins-v19-latin-900.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/poppins-v19-latin-900.svg#Poppins")
      format("svg"); /* Legacy iOS */
}
/* poppins-900italic - latin */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 900;
  src: url("../fonts/poppins-v19-latin-900italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/poppins-v19-latin-900italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/poppins-v19-latin-900italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../fonts/poppins-v19-latin-900italic.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/poppins-v19-latin-900italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/poppins-v19-latin-900italic.svg#Poppins") format("svg"); /* Legacy iOS */
}
