.box {
  display: block;
  width: 100%;
  border: 1px solid $boxBorderColor;
  background: $boxBackground;
  margin-bottom: $primaryPadding;

  -webkit-box-shadow: $boxShadow;
  -moz-box-shadow: $boxShadow;
  box-shadow: $boxShadow;

  .box-title {
    color: $primaryColor;
  }

  .box-content {
    padding: $primaryPadding;
  }
}

a.box:hover {
  background: $hoverColor;
}

.small.box {
  .box-content {
    padding: $primaryPadding / 2;
  }
}

.no-margin.box {
  margin: 0;
}

.no-padding.box {
  .box-content {
    padding: 0;
  }

  .admin-list {
    .admin-list-item {
      padding: 0;
    }
  }
}
