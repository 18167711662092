$primaryColor: #d54427;
$darkBlue: #263354;
$lightBlue: #429ea6;
$lightGray: #f3f3ee;
$orange: #fc913f;

$primaryFontFamily: "Poppins", Helvetica, Arial, sans-serif;
$headingFontFamily: "Work Sans", Helvetica, Arial, sans-serif;

$primaryPadding: 20px;

$bodyBackground: white;

$boxBackground: white;
$boxBorderColor: rgb(230, 230, 230);
$hoverColor: #fbeae8;
$boxShadow: none;

$breakSmall: 860px;
$breakMedium: 1024px;
$breakLarge: 1200px;
$breakXLarge: 1440px;

$modalBackground: rgba(255, 255, 255, 0.95);

/* INDEX */
$indexHeroLogoHeight: 40px;
$indexHeroLogoPadding: 20px;

@import "foundationsettings";
@import "foundation-sites/scss/foundation";
@include foundation-everything;

@import "fonts";
@import "main";
@import "helpdesk";

@import "../components/reusables/Box/box";
@import "../components/reusables/MMPopup/mmpopup";
